<template>
	<DocumentPreview
		v-if="open"
		:items="documents"
		:loading="isLoading"
		:value="currentDocument"
		:version="version"
		@close="closePreview"
		@input="selectDocument($event)"
	/>
</template>

<script>
import { Folder } from '@/classes/Documents/Folder.class'

import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

import DocumentEditionService from '@/services/Documents/DocumentEditionService'
import DocumentVersionsService from '@/services/Documents/DocumentVersionsService'
import { mapMutations, mapState } from "vuex";
import { isViewable } from "@/helpers/files";

export default {
	name: 'DocumentsManagerPreview',
	components: {
		DocumentPreview: () => ({
			component: import('@/components/Documents/Preview/DocumentPreview')
		})
	},
	mixins: [DocumentsManagerModuleGuard],
	props: {
		folder: {
			default: null,
			required: false,
			type: Folder
		},
		isLoading: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			default: null,
			required: false,
			type: String
		},
		versionId: {
			default: null,
			required: false,
			type: Number
		}
	},
	data: function () {
		return {
			version: null
		}
	},
	computed: {
		...mapState({
			currentDocument: state => state.documents.preview.current,
			sorting: state => state.documents.sort,
		}),
		documents: function () {
			let result = []
			if (this.folder && Array.isArray(this.folder.documents)) {
				if (this.versionId) {
					const document = this.folder.documents.find(doc => doc.hash == this.value)
					if (this.isViewable(document) || this.isEditable(document)) {
						result.push(document)
					}
				} else {
					this.folder.documents.forEach(doc => {
						if (this.isViewable(doc) || this.isEditable(doc)) {
							result.push(doc)
						}
					})
				}
			}

			if (['asc', 'desc'].includes(this.sorting?.sort?.order)) {
				result.sort(this.appService.compareValues(this.sorting.sort.field, this.sorting.sort.order))
			}

			return result
		},
		open: function () {
			return !!this.value && ((this.versionId && this.version) || !this.versionId)
		}
	},
	watch: {
		documents(newDocuments) {
			this.setDocuments(newDocuments)
		},
		isLoading: {
			handler: 'setDocument'
		},
		value: {
			handler: 'setDocument'
		}
	},
	mounted: function () {
		this.setDocument()
	},
	methods: {
		...mapMutations({
			setCurrentDocumentByHash: 'documents/preview/setCurrentByHash',
			setCurrentDocument: 'documents/preview/setCurrent',
			resetCurrentDocument: 'documents/preview/resetCurrent',
			setDocuments: 'documents/preview/setDocuments',
		}),
		closePreview: function () {
			const query = { ...this.$route.query }
			delete query.document_id
			delete query.version_id
			this.appService.goTo({
				name: 'documents',
				params: this.$route.params,
				query
			})

			this.resetCurrentDocument()
			this.version = null
		},
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.preview.OPEN, action: this.openPreview },
				{ event: this.events.preview.CLOSE, action: this.closePreview }
			]
		},
		isEditable: function (document) {
			return this.appService.hasModule('document-edition') && DocumentEditionService.isDocumentOnlineEditable(document)
		},
		isViewable: function (document) {
			return document && isViewable(document.type)
		},
		openPreview: function (event) {
			const document = event.document
			if (!document) {
				return
			}
			this.selectDocument(document, event.version)
		},
		selectDocument: async function (doc, version) {
			if (!this.isViewable(doc) && !this.isEditable(doc)) {
				return;
			}
			const query = {
				...this.$route.query,
				document_id: doc.hash
			}
			if (version) {
				query.version_id = version.id
			}
			if (!this.isEditable(doc)) {
				this.goToDocumentsPreview(query)
				return;
			}

			try {
				const { local, url } = await DocumentEditionService.getDocumentEditionLaunchingOptions(
					this.vendorId,
					doc,
					{ id: query.version_id },
					this.conditionalDisplay.isMobileApp
				)
				if (local) {
					window.open(url, '_blank')
					return;
				}
				this.goToDocumentsPreview(query)
			} catch (error) {
				// eslint-disable-next-line no-console
			}

		},
		goToDocumentsPreview (query) {
			this.appService.goTo({
				name: 'documents',
				params: this.$route.params,
				query
			})
		},
		setDocument: async function () {
			const documentHash = this.value
			if (!documentHash || this.isLoading) {
				return
			}

			this.setCurrentDocumentByHash(documentHash)
			if (! this.currentDocument) {
				this.closePreview();
				return
			}

			const versionId = this.versionId
			if (versionId) {
				if (!this.currentDocument.versions || this.currentDocument.versions.length === 0) {
					await DocumentVersionsService.getDocumentVersions(this.vendorId, this.currentDocument)
				}

				if (this.currentDocument.versions.length > 0) {
					this.version = this.currentDocument.versions.find(v => v.id === versionId)
				}
			}
		}
	}
}
</script>
